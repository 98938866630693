import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const packageRelease = "welp-frontend@" + process.env.PACKAGE_VERSION

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://ead4a47589694cef872b09d96feaf0aa@o128370.ingest.sentry.io/283331",

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: packageRelease,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
} else {
    console.info("Sentry not activated outside production")
}