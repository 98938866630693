import React, { useState } from 'react'
import { COLOR_LIST, DEFAULT_COLORS } from '../../welp.utils'
import ResultsSubcategory from './ResultsSubcategory'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js'
import { Line } from 'react-chartjs-2'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const OPTIONS = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      max: 100
    }
  }
}

const ResultsArea = ({ name, id, subcategories, comparisons, mainSourceName, ...rest }) => {

  const [open, setOpen] = useState(true)
  const questionTexts = []
  const averages = {}

  subcategories.forEach(subcat => {
    subcat.questions.forEach(questionData => {
      // if length of answers > 1...!!!
      const { question, answers } = questionData
      if (answers && answers.length && question.type == 'SC') {
        questionTexts.push(question.text)
        answers.forEach((group, index) => {
          const name = group.group ? group.group : (mainSourceName ? mainSourceName : I18N._("All"))
          if (averages[name]) {
            averages[name]["data"].push(group.avg)
          } else (
            averages[name] = { label: name, data: [group.avg], borderColor: COLOR_LIST[index] }
          )
        })

        const groupCount = answers.length

        comparisons.forEach(({ data, name }, index) => {
          const { id, results } = data
          if (results && results[question.id] !== undefined) {
            const key = `comparison-${id}`
            results[question.id].forEach(({ group, avg }, groupIndex) => {
              const comparisonKey = key + group
              if (averages[comparisonKey]) {
                averages[comparisonKey]["data"].push(avg)
              } else {
                const groupName = group !== null ? ": " + group : ""
                const fullName = `${name} ${groupName}`.trim()
                averages[comparisonKey] = { label: fullName, data: [avg], borderColor: COLOR_LIST[groupCount + index + groupIndex] }
              }
            })
          }
        })
      }
    })
  })

  const datasets = Object.values(averages)
  const data = questionTexts.length ? {
    datasets: datasets,
    labels: questionTexts
  } : null

  return <div key={id} className={"area-overview category accordion " + (open ? "open" : "")}>
    <header onClick={() => setOpen(!open)}>
      <h3>
        {name}
      </h3>
      <button className="btn btn-sm btn-transparent">
        {
          open ?
            <>
              <span className="glyphicon glyphicon-chevron-up" />
            </>
            :
            <>
              <span className="glyphicon glyphicon-chevron-down" />
            </>
        }
      </button>
    </header>

    {open &&
      <div className="accordion__content">
        {data !== null &&
          <>
            <p>
              {I18N._("Line visibility can be toggled by clicking on the legend. Hover over the dots for the exact question and mean answer value.")}
            </p>
            <Line data={data} height="100px" options={OPTIONS} />
          </>
        }
        {
          subcategories.map((props, index) =>
            <ResultsSubcategory key={props.id} comparisons={comparisons} mainSourceName={mainSourceName} {...props} {...rest} />
          )
        }
      </div>
    }

  </div>
}

export default ResultsArea