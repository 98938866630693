import React from 'react';

import SemesterChooser from './SemesterChooser';


export default class extends React.Component {

  static defaultProps = {
    audiences: [],
    school_results_url_template: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      chosenSemesters: props.audiences.reduce((col, a) => {
        col[a.ident] = a.semesters.length > 0 ? a.semesters[a.semesters.length - 1][0] : null
        return col
      }, {})
    }

    this.semesterChosen = this.semesterChosen.bind(this)
    this.open = this.open.bind(this)
  }

  semesterChosen(event, audienceIdent) {
    event.preventDefault()
    const value = event.target.value
    const newState = { ...this.state.chosenSemesters, [audienceIdent]: value }
    this.setState({ chosenSemesters: newState })
  }

  open(event, audienceIdent) {
    event.preventDefault()
    const value = this.state.chosenSemesters[audienceIdent]
    const url = this.props.school_results_url_template.replace("SEMESTER", value).replace(999, audienceIdent)
    // console.log(url)
    window.location.href = url
  }

  render() {
    return (
      <div>
        {this.props.audiences.map((audience) =>
          <div className="well" key={audience.ident}>
            <h3>
              {I18N._(audience.name)}
            </h3>

            <div className="section">
              <h4 className="main">{I18N._("View results")}</h4>
              {
                audience.semesters.length > 0 ?
                  <div className="semester-dropdown-and-button">
                    <SemesterChooser name=""
                      value={this.state.chosenSemesters[audience.ident]}
                      onChange={(evt) => this.semesterChosen(evt, audience.ident)}
                      availableSemesters={audience.semesters} />

                    <button className="btn btn-sm btn-success" onClick={(evt) => this.open(evt, audience.ident)}>{I18N._("Open")}</button>
                  </div>
                  :
                  <div className="action no-results">
                    {I18N._("There are no results")}
                  </div>
              }
            </div>
          </div>
        )}
      </div>
    )
  }
}
