import React from 'react'


const Loading = () => {
  return (
    <progress id="progress-bar" aria-label="Loading…"></progress>
  )
}


export default Loading