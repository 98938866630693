import React from 'react';
import { ResultsDataContext } from '../DataContext';


const openAndFetchIfNeeded = (formContext, question, setOpen, answers) => {
  setOpen(true);
  // if (answers === undefined) {
  formContext.requestFreetextAnswers(question.id)
  // }
}

const _FreetextAnswers = ({ question, formContext }) => {

  const [opened, setOpen] = React.useState(false)
  const answers = formContext.freetextAnswers[question.id]
  const enoughAnswers = answers && answers !== "Not enough answers"

  return (
    <div className="answer-row">
      {
        <div className={"freetext-container " + (opened ? "open" : "")}>
          <div className="header">
            <button
              onClick={() => opened ? setOpen(false) : openAndFetchIfNeeded(formContext, question, setOpen, answers)}
              className="btn btn-sm btn-default">
              {
                opened ?
                  <>
                    {I18N._('Hide answers')}
                    <span className="glyphicon glyphicon-minus" aria-hidden="true"></span>
                  </>
                  :
                  <>
                    {I18N._('View answers')}
                    <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>
                  </>

              }
            </button>
          </div>
          {
            opened &&
            <div>
              {
                answers === undefined ?
                  <div> Fetching freetext answers </div>
                  :
                  enoughAnswers ?
                    answers.map((a, i) =>
                      <div className={"freetext " + (i % 2 == 0 ? 'even' : 'odd')} key={i} title={a}>{a}</div>
                    ) :
                    <div className="no-results"> {I18N._('There are no results')} </div>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}


const FreetextAnswers = ({ question }) => {
  return <ResultsDataContext.Consumer>
    {formContext =>
      <_FreetextAnswers formContext={formContext} question={question} key={formContext.counter} />
    }
  </ResultsDataContext.Consumer>
}

FreetextAnswers.defaultProps = {
  question: {},
}

export default FreetextAnswers

