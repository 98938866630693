import React from 'react'
import { COLOR_LIST } from '../../welp.utils'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  ArcElement,
  BarElement,
} from 'chart.js'
import { Line, Pie, Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
)


const OPTIONS = {
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      // max: 100
    }
  }
}

// Some cryptic re-rendering bug plagued the basic results area where the
// graphs would show varying results after a re-render of the same data
// some had appearing axes, some started showing labels as values.
// fixed now with PureComponent, but might be popping up again.. next
// fix to try is rendering with different data configuration

export const PieChart = ({ datasets, labels }) => {

  if (!datasets || !labels) {
    return null
  }

  datasets[0].backgroundColor = COLOR_LIST
  const data = {
    datasets,
    labels
  }

  return <Pie data={data} options={OPTIONS} />
}

export const LineChart = ({ datasets, labels, label }) => {


  if (!datasets || !labels) {
    return null
  }

  const data = {
    datasets,
    labels
  }
  datasets[0].backgroundColor = COLOR_LIST
  datasets[0].label = label
  return <Line data={data} options={OPTIONS} />

}

export const BarChart = ({ datasets, labels, label, vertical }) => {

  if (!datasets || !labels) {
    return null
  }

  const data = {
    datasets,
    labels
  }

  datasets[0].backgroundColor = COLOR_LIST
  datasets[0].label = label
  const options = vertical === true ? { ...OPTIONS, indexAxis: 'y' } : OPTIONS
  return <Bar key={datasets[0].label} data={data} options={options} />
}
