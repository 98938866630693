

function loadAutocompleteField() {
  const options = {
    types: ['address'],
    componentRestrictions: { country: "fi" }
  };
  const $input = $('#id_raw_address')
  $input.on('keydown', function (event) {
    if (event.keyCode == 13) {
      console.info('preventing form sending on enter')
      event.preventDefault();
      return false;
    }
  })
  const input = $input.get(0);
  const autocomplete = new google.maps.places.Autocomplete(input, options);

  autocomplete.addListener('place_changed', function () {
    const p = autocomplete.getPlace();
    console.info('place changed => ', p);
    // user did not select anything, let's see if we can autoselect the first option
    // by querying the places service with the same input and automatically selecting
    // first of what we get
    if (typeof p.address_components == 'undefined') {
      console.info("  .. no place was selected, taking the first autocomplete result for", p.name)
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        Object.assign(options,
          {
            'input': p.name,
            'offset': p.name.length,
          }
        ),
        function listentoresult(list, status) {
          if (list == null || list.length == 0) {
            console.info("Autocomplete: no results");
          } else {
            console.info('  .... we have a result from autocomplete, forwarding to places service', list)
            const placesService = new google.maps.places.PlacesService($('#googlemaps-places-attribution').get(0));
            placesService.getDetails(
              { 'reference': list[0].reference },
              function detailsresult(detailsResult, status) {
                console.info('  ...... result from places service', detailsResult)
                $input.val(detailsResult.formatted_address);
                updateHiddenFields(detailsResult);
              }
            );
          }
        }
      );
    } else {
      updateHiddenFields(p);
    }

    return false;
  });
}

function updateHiddenFields(p) {
  const comps = extractLocationComponents(p.address_components)
  $('#id_city').val(comps.city)
  $('#id_street').val(comps.street + " " + (comps.number || ''))
  $('#id_lat').val(p.geometry.location.lat())
  $('#id_lng').val(p.geometry.location.lng())
  validateSubmit()
}

function validateSubmit() {
  const requiredFields = ['#id_email', '#id_city', '#id_street', '#id_lat', '#id_lng']
  let isValid = true;
  for (let f of requiredFields) {
    let val = $(f).val()
    if (!val || val == '') {
      isValid = false;
    }
  }
  if (isValid) {
    $('#submit-id-submit').prop('disabled', false)
  } else {
    $('#submit-id-submit').prop('disabled', true)
  }
}

// create a flat structure for an address to make life simpler
function extractLocationComponents(addressComponents) {
  let components = {};
  $.each(addressComponents, function (i, c) {
    if ($.inArray('country', c.types) != -1) {
      components.country_code = c.short_name;
      components.country = c.long_name;
    } else if ($.inArray('locality', c.types) != -1 ||
      $.inArray('administrative_area_level_3', c.types) != -1) {
      components.city = c.long_name;
    } else if ($.inArray('postal_code', c.types) != -1) {
      components.post_code = c.long_name;
    } else if ($.inArray('street_number', c.types) != -1) {
      components.number = c.long_name;
    } else if ($.inArray('route', c.types) != -1) {
      components.street = c.long_name;
    } else if ($.inArray('neighborhood', c.types) != -1) {
      components.neighborhood = c.long_name;
    }
  });
  return components;
}


export default function (opts) {
  loadAutocompleteField()
  validateSubmit()
  $('#id_email, #id_school_name').on('change', validateSubmit)
};
