import React from 'react';

export const ViewChooser = ({ name, value, availableClasses, targetAudience, onChange }) =>
    <select name={name} className="form-control" onChange={onChange}
        defaultValue={value}>
        <option value="all">{I18N._("all")}</option>
        {targetAudience !== 4 && <option value="gradesSeparated">{I18N._("Grade levels separated")}</option>}
        {availableClasses && availableClasses.length && <option value="" disabled>{I18N._("Limit to class")}</option>}
        {
            availableClasses.map(([classId, gradeAndId]) =>
                <option value={gradeAndId} key={gradeAndId}>{classId}</option>
            )
        }
    </select>


export const ComparisonChooser = ({ available, onChange }) =>
    <select name="comparison-chooser" className="form-control" onChange={onChange}
        defaultValue="select">
        {
            available.map(({ id, name, disabled }) =>
                <option value={id} key={id} disabled={disabled === true}>{name}</option>
            )
        }
    </select>


export const ExportButtons = ({ excelCallback, shareCallback }) => {
    return <div className="export-buttons">
        <button onClick={(e) => { e.preventDefault(); excelCallback(e) }}
            className="btn btn-default export-button"
            title={I18N._("Excel (XLSX) spreadsheet format")}>
            <span className="xls-emblem"></span>
        </button>
        {shareCallback &&
            <button onClick={(e) => { e.preventDefault(); shareCallback(e) }}
                className="btn btn-default"
                title={I18N._("Share the current view")}>
                <span className="glyphicon glyphicon-share" aria-hidden="true"></span>
            </button>
        }
    </div>
}
