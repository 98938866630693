import React from 'react';
import Results from './Results';
import { determineComparisonName } from '../../welp.utils'

export default class extends React.Component {

  static defaultProps = {
    results: {},
    comparison_results: {},
    main_query_spec: {},
    comparison_query_specs: [],
    school: {},
    form: {}
  };

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let compareWith = this.props.comparison_results.map((cr) => cr["type"]).join(",")

    if (compareWith.length === 0) {
      compareWith = "none"
    }

    const comparisons = this.props.comparison_results.map((comparison) => {
      const comparisonSpec = this.props.comparison_query_specs.find((c) => c.id == comparison.id && c.type == comparison.type)
      return {
        data: comparison,
        with: comparison.type,
        name: determineComparisonName(comparisonSpec, this.props.main_query_spec)
      }
    })

    let mainSourceName = I18N._("us")
    // const comparisonSet = compareWith !== "none" ? this.props.comparison_results[0] : null  // allow only one comparison here..
    return (
      <div className="container">
        <Results
          resultsSet={this.props.results}
          mainSourceName={mainSourceName}
          comparisons={comparisons}
          skipFirstArea={true} />
      </div>
    )
  }
}
