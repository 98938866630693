import React from 'react'

import Question from './Question'


class AnswerRowAccordion extends React.Component {

  static defaultProps = function () {
    return {
      answers: [],
      open: false,
      comparisons: [],
      mainSourceName: null,
    }
  }();

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open && true
    };

  }

  render() {
    return (
      <div className={"accordion " + (this.state.open ? "open" : "")}>
        <button onClick={() => this.setState({ open: !this.state.open })} className="btn btn-sm btn-default toggle">
          {
            this.state.open ?
              <>
                {I18N._('Hide individual questions')}
                <span className="glyphicon glyphicon-minus" />
              </>
              :
              <>
                {I18N._('View individual questions')}
                <span className="glyphicon glyphicon-plus" />
              </>
          }
        </button>
        <div className="accordion__content">
          {
            this.state.open &&
            this.props.answers.map((props, index) =>
              <Question key={index}
                comparisons={this.props.comparisons}
                mainSourceName={this.props.mainSourceName}
                {...props} />
            )
          }
        </div>
      </div>
    )
  }
}

export default AnswerRowAccordion