import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import Loading from './Loading'


const SCHOOL_TYPE_NAME_PLURALS = {
  "elementary": "Elementary schools",
  "vocational": "Vocational schools",
  "high_school": "High schools"
}

async function fetchSummary(slug) {
  const citySummaryUrl = `/api/v1/city-admins/${slug}/summary`
  const data = await fetch(citySummaryUrl,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
    })
    .then(response => response.json())
    .then(obj => {
      if (obj.error) {
        alert("Fail: " + JSON.stringify(obj))
        return null
      } else {
        const schoolStats = {
          answersThisSemester: 0,
          answersThisYear: 0,
          noAnswers: 0
        }
        const processedSchools = obj.summary.map((school) => {
          school.last_answerset_date = DateTime.fromISO(school.last_answerset_date).setLocale("fi")
          if (school.has_answers_in_current_semester) {
            schoolStats.answersThisSemester++
          } else if (school.has_answers_in_current_year) {
            schoolStats.answersThisYear++
          } else {
            schoolStats.noAnswers++
          }
          return school
        })
        return { loading: false, summary: processedSchools, city: obj.city, schoolStats }
      }
    })
    .catch(err => {
      console.error(err)
      alert("Error requesting data from backend: " + err)
      return null
    })
  return data
}

function _orderByAnswerStatus(summariesBySchoolType) {
  const ordered = {}
  for (var schoolType in summariesBySchoolType) {
    ordered[schoolType] = summariesBySchoolType[schoolType]
      .sort((a, b) => {
        if (a.has_answers_in_current_semester && !b.has_answers_in_current_semester) {
          return -1
        } else if (!a.has_answers_in_current_semester && b.has_answers_in_current_semester) {
          return 1
        } else if (a.has_answers_in_current_year && !b.has_answers_in_current_year) {
          return -1
        } else if (!a.has_answers_in_current_year && b.has_answers_in_current_year) {
          return 1
        } else {
          return a.name.localeCompare(b.name)
        }
      })
      .reduce((col, school) => {
        if (school.has_answers_in_current_semester) {
          col.semester.push(school)
        } else if (school.has_answers_in_current_year) {
          col.year.push(school)
        } else {
          col.none.push(school)
        }
        return col
      }, { semester: [], year: [], none: [] })
  }
  return ordered
}

function _groupBySchoolType(summaries) {
  return summaries.reduce((col, school) => {
    if (col[school.school_type] === undefined) {
      col[school.school_type] = []
    }
    col[school.school_type].push(school)
    return col
  }, {})
}

const SchoolListing = ({ schoolSummary }) => {
  return <div
    className={['school-summary card',
      (schoolSummary.has_answers_in_current_year ? 'answers-in-current-year' : ''),
      (schoolSummary.has_answers_in_current_semester ? 'answers-in-current-semester' : '')].join(' ')}>
    <div className="card-body">
      <a href={`/answers/${schoolSummary.id}`}>{schoolSummary.name}</a>
      <div dangerouslySetInnerHTML={{
        __html:
          I18N._(
            "Last answers on <span>%(date)s</span>", {
            params: {
              date:
                schoolSummary.last_answerset_date.toLocaleString()
            }
          })

      }} />
    </div>
  </div>
}


const CityAdminList = ({ city_slug }) => {
  const [loading, setLoading] = useState(false)
  const [city, setCity] = useState(null)
  const [summaries, setSummaries] = useState(null)

  useEffect(() => {
    fetchSummary(city_slug).then((data) => {
      if (data) {
        setSummaries(_orderByAnswerStatus(_groupBySchoolType(data.summary)))
        setCity(data.city)
        setLoading(false)
      }
    })
  }, [city_slug])


  return (
    <section className="school-summaries">
      <h2>{city?.name}</h2>

      {loading ?
        <Loading />
        :
        <div>
          {summaries !== null && Object.entries(summaries).map(([schoolType, schools]) =>
            <div key={schoolType}>
              <h3>{I18N._(SCHOOL_TYPE_NAME_PLURALS[schoolType])}</h3>
              {["semester", "year", "none"].map((type) =>
                <div className="school-summaries-list" key={type}>
                  {schools[type].map((schoolSummary) =>
                    <SchoolListing schoolSummary={schoolSummary} key={schoolSummary.id} />
                  )}
                </div>
              )}
            </div>

          )}
        </div>
      }
    </section>
  )
}

CityAdminList.defaultProps = {
  city_slug: {
    type: String,
    default: null
  }
}

export default CityAdminList
