import React from 'react';

export default () =>
  <div className="fetching-data-bar">
    <div className="progress progress-striped active">
      <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
        style={{ width: '100%' }}>
        <span>{I18N._('Fetching data')}</span>
      </div>
    </div>
  </div>
