import React from 'react';
import { ResultsDataContext } from '../DataContext';
import AnswerRow from './AnswerRow';
import FreetextAnswers from './FreetextAnswers';


const Question = ({ question, answers, showAmounts, comparisons, mainSourceName }) => {

  let MainAnswers = null
  let ComparisonSections = []

  if (question.type !== "FT") {
    MainAnswers = answers.map((rowOfAnswers, index) =>
      <AnswerRow key={index}
        question={question}
        showAmounts={showAmounts}
        rowName={rowOfAnswers.group}
        bins={rowOfAnswers.bins}
        count={rowOfAnswers.count} />
    )
    ComparisonSections = comparisons.reduce((col, { name, data }) => {
      const results = data.results
      const answersInComparison = results[question.id]
      if (answersInComparison) {
        const ComparisonAnswers = answersInComparison
          .map(({ group, bins, count }, index) =>
            <AnswerRow key={index}
              question={question}
              showAmounts={showAmounts}
              rowName={group}
              bins={bins}
              count={count} />
          )
        col.push([name, ComparisonAnswers])
      }
      return col
    }, [])
  }

  return (
    <ResultsDataContext.Consumer>
      {({ showFreetextQuestions }) =>
        question.type === "FT" && showFreetextQuestions === false ?
          null
          :
          <div className="question" data-id={question.id}>
            {
              question.text &&
              <div className="text">
                {question.text}
              </div>
            }
            {
              question.type === "FT" ?
                <FreetextAnswers question={question} />
                :
                <div className="answer-sources">
                  <div className="answer-source">
                    {comparisons.length > 0 && <div className="answer-source__name"> {mainSourceName} </div>}
                    <div className="answer-source__rows"> {MainAnswers} </div>
                  </div>

                  {
                    ComparisonSections.map(([name, compAnswers], index) =>
                      <div className="answer-source" key={name + index}>
                        <div className="answer-source__name"> {name}</div>
                        <div className="answer-source__rows"> {compAnswers} </div>
                      </div>
                    )
                  }
                </div>
            }
          </div>
      }
    </ResultsDataContext.Consumer>
  )
}

Question.defaultProps = {
  question: {},
  answers: [],
  showAmounts: true,
}

export default Question

