export default function fakeI18N(tkey, locale, replaceVars) {
  // FIXME: this gets overridden by the function in javascript_i18n on runtime!
  console.error("SHOULD NOT CALL fakeI18N")

  locale = locale ? locale : window.CURRENT_LOCALE

  const strings = window.I18N_STRINGS

  let translation = strings[tkey] && strings[tkey][locale]
    ? strings[tkey][locale]
    : "!(" + tkey + ")!"

  if (replaceVars) {
    if (translation.indexOf('{{') !== -1) {
      translation = translation.replace(
        /\{\{\s?([a-z_]+)\s?\}\}/g,
        function (_f, m) { return replaceVars[m] }
      )
    }
    if (translation.indexOf('%(') !== -1) {
      translation = translation.replace(
        /%\(\s?([a-z_]+)\s?\)s/g,
        function (_f, m) { return replaceVars[m] }
      )
    }
  }

  return translation
}