import React from 'react';

import { BarChart, PieChart } from './Charts';
import ExpandableChart from "./ExpandableChart"
import { getSemesterName } from '../../welp.utils';


const analyzeQuestion = ({ question, answers }, groupIndex) => {
  if (!groupIndex) {
    groupIndex = 0
  }

  const combinedBins = answers.reduce((col, group) => {
    group.bins.forEach((val, indx) => {
      if (col[indx] === undefined) {
        col[indx] = val
      } else {
        col[indx] += val
      }
    })
    return col
  }, [])

  const analysis = combinedBins
    .reduce((col, val, indx) => {
      if (col.maxVal < val) {
        col.maxAt = indx
        col.maxVal = val
      }
      col.total += val
      return col
    }, { maxAt: -1, maxVal: -1, total: -1 })
  analysis.maxPercentage = Math.round(analysis.maxVal / analysis.total * 100)
  analysis.maxAnswer = question.choices[analysis.maxAt]
  analysis.chartParams = {
    datasets: [
      { data: combinedBins, label: 1, }
    ],
    labels: question.choices.map((choice) => choice.toString()),
    label: question.text
  }
  return analysis
}


class BasicAreaResults extends React.Component {

  state = {
    form_amount: 0,
    total_respondents: 0,
  }

  constructor(props) {
    // {% blocktrans trimmed with total_respondents=results.total_respondents target_audience=results.target_audience start_time=results.response_start_time end_time=results.response_end_time %}
    // {% if 'school_count' in results %}
    // {% blocktrans trimmed with questions_in_total=results.question_count areas_in_total=results.area_count subcategories_in_total=results.subcategory_count %}
    // {% with answer_key=results.graphs.sex.res.max_at answer_percentage=results.graphs.sex.res.max_perc %}
    super(props)

    const { mainResults, unique_class_ids_with_counts } = props

    const state = {
      form_amount: props.forms_count,
      semester: getSemesterName(props.semester),
      start_time: props.start_time,
      end_time: props.end_time,
      school_count: props.schools_count,
      target_audience: props.target_audience_name,
      biggest_class_id: null,
      total_respondents: 0,
      questions_in_total: "---",
      areas_in_total: "---",
      subcategories_in_total: "---",
      gender_max_key: "---",
      gender_max_percentage: "---",
    }

    if (mainResults) {
      const basicAreaCategory = mainResults.results[0]
      const basicAreaQuestions = basicAreaCategory.subcategories[0].questions
      const genderQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "sex")
      const gradeQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "grade")
      // const classQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "class")
      const seniorityQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "seniority")
      const langQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "lang")
      const homeQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "home")
      const ageQuestion = basicAreaQuestions.find(({ question }) => question.sep_for === "age")

      const genderAnalysis = analyzeQuestion(genderQuestion)
      state.gender_max_key = genderAnalysis.maxAt
      state.gender_max_percentage = genderAnalysis.maxPercentage
      state.genderChartParams = genderAnalysis.chartParams

      if (gradeQuestion) {
        const gradeAnalysis = analyzeQuestion(gradeQuestion)
        state.biggest_grade = gradeAnalysis.maxAnswer
        state.gradeChartParams = gradeAnalysis.chartParams
      }

      const seniorityAnalysis = analyzeQuestion(seniorityQuestion)
      state.biggest_seniority = seniorityAnalysis.maxAnswer
      state.biggest_seniority_percentage = seniorityAnalysis.maxPercentage
      state.seniorityChartParams = seniorityAnalysis.chartParams

      const ageAnalysis = analyzeQuestion(ageQuestion)
      state.biggest_age = ageAnalysis.maxAnswer
      state.biggest_age_percentage = ageAnalysis.maxPercentage
      state.ageChartParams = ageAnalysis.chartParams

      if (langQuestion) {
        const langAnalysis = analyzeQuestion(langQuestion)
        state.biggest_language = langAnalysis.maxAnswer
        state.biggest_language_percentage = langAnalysis.maxPercentage
        state.langChartParams = langAnalysis.chartParams
      }

      if (homeQuestion) {
        const homeAnalysis = analyzeQuestion(homeQuestion)
        state.biggest_home_situation = homeAnalysis.maxAnswer
        state.biggest_home_situation_percentage = homeAnalysis.maxPercentage
        state.homeChartParams = homeAnalysis.chartParams
      }

      if (unique_class_ids_with_counts) {
        const biggest = unique_class_ids_with_counts.sort(([aClassId, aCount], [bClassId, bCount]) => aCount < bCount)[0]
        state.biggest_class = biggest[0]
        state.biggestClassChartParams = {
          datasets: [{ data: unique_class_ids_with_counts.map((u) => u[1]) }],
          labels: unique_class_ids_with_counts.map((u) => u[0]),
          label: ""
        }
      }

      state.total_respondents = this.props.set_count;
      state.areas_in_total = mainResults.results.length
      state.subcategories_in_total = mainResults.results.reduce((col, area) => {
        return col + area.subcategories.length
      }, 0)
      state.questions_in_total = mainResults.results.reduce((col, area) => {
        return col + area.subcategories.reduce((col, subcat) => col + subcat.questions.length, 0)
      }, 0)
    }

    this.state = state
  }

  // always false.. prevent the damn horizontal bar charts from re-rendering
  shouldComponentUpdate(olds, news) {
    return false;
  }

  render() {
    if (this.state.form_amount === 0) {
      return null
    }

    return (
      <div className="basic-results">

        <section>

          <div className="row header highlighted-text big">
            <div className="col-sm-12">
              <p>
                {
                  <i dangerouslySetInnerHTML={{
                    __html: I18N._("The questionnaire for <span>%(target_audience)s</span> has been filled <span>%(total_respondents)s</span> times during school year <span>%(semester)s</span>. The answers have been given between <span>%(start_time)s</span> and <span>%(end_time)s</span>.",
                      { params: this.state })
                  }}></i>
                }

                {<i dangerouslySetInnerHTML={{ __html: I18N._("In total <span>%(count)s</span> schools completed the survey.", { params: { count: this.state.school_count } }) }}></i>}
              </p>
              <p>
                {this.state.form_count === 1 ?
                  <i dangerouslySetInnerHTML={{ __html: I18N._("These results were combined from <span>one</span> form.") }}></i>
                  :
                  <i dangerouslySetInnerHTML={{ __html: I18N._("These results were combined from <span>%(form_amount)s</span> forms.", { params: this.state }) }}></i>
                }
                {<i dangerouslySetInnerHTML={{ __html: I18N._("There are <span>%(questions_in_total)s</span> questions in total divided into <span>%(areas_in_total)s</span> areas and <span>%(subcategories_in_total)s</span> subcategories.", { params: this.state }) }}></i>}
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="row">
            <div className="col-md-12 highlighted-text big">
              <i>{I18N._("Out of the respondents")}</i>
              {this.state.gender_max_key == 0 && <i dangerouslySetInnerHTML={{ __html: I18N._("<span>%(gender_max_percentage)s%</span> were <span>boys</span>.", { params: this.state }) }}></i>}
              {this.state.gender_max_key == 1 && <i dangerouslySetInnerHTML={{ __html: I18N._("<span>%(gender_max_percentage)s%</span> were <span>girls</span>.", { params: this.state }) }}></i>}
              {this.state.gender_max_key != 1 && this.state.gender_max_key != 0 && <i dangerouslySetInnerHTML={{ __html: I18N._("<span>%(gender_max_percentage)s%</span> did not specify gender.", { params: this.state }) }}></i>}
              {this.state.biggest_grade && <i dangerouslySetInnerHTML={{ __html: I18N._("Most respondents were from the <span>%(biggest_grade)s grade</span>.", { params: this.state }) }}></i>}

            </div>
          </div>
          <div className="row">
            <ExpandableChart className="col-md-4" ChartClass={PieChart} params={this.state.genderChartParams} />
            {this.state.gradeChartParams && <ExpandableChart className="col-md-8" ChartClass={BarChart} vertical={true} params={this.state.gradeChartParams} />}
          </div >
        </section >

        <section>
          <div className="row">
            <div className="col-md-12 highlighted-text big">
              {/* {% if 'class_id_max_label' in results %} */}
              {/* {% blocktrans trimmed with biggest_className=results.class_id_max_label biggest_age=results.graphs.age.max_label %} */}
              {
                this.state.biggest_class ?
                  <i dangerouslySetInnerHTML={{ __html: I18N._("Out of the classes <span>%(biggest_class)s</span> was most active and of age groups the <span>%(biggest_age)s</span> year olds answered the most.", { params: this.state }) }}></i>
                  :
                  <i dangerouslySetInnerHTML={{ __html: I18N._("Out of the age groups the <span>%(biggest_age)s</span> year olds answered the most.", { params: this.state }) }}></i>
              }
              {/* {% endblocktrans %} */}
              {/* {% endif %} */}
            </div>
          </div>
          <div className="row">
            {this.state.biggestClassChartParams ?
              <>
                <ExpandableChart className="col-md-6" ChartClass={BarChart} params={this.state.biggestClassChartParams} />
                <ExpandableChart className="col-md-6" ChartClass={BarChart} params={this.state.ageChartParams} />
              </> :
              <ExpandableChart className="col-md-12" ChartClass={BarChart} params={this.state.ageChartParams} />
            }
            {/* {% endif %} */}
          </div >
        </section >

        <section>
          <div className="row">
            <div className="col-md-12 highlighted-text big">
              {/* {% if results.graphs.lang %} */}
              {/* {% blocktrans trimmed with biggest_language=results.graphs.lang.max_label biggest_language_percentage=results.graphs.lang.max_perc %} */}
              {this.state.biggest_language && <i dangerouslySetInnerHTML={{ __html: I18N._("<span class='capitalize'>%(biggest_language)s</span> is the most spoken language with <span>%(biggest_language_percentage)s%</span> of the respondents.", { params: this.state }) }}></i>}
              {/* {% endblocktrans %} */}
              {/* {% endif %} */}
              {/* {% blocktrans trimmed with biggest_age=results.graphs.seniority.max_label biggest_age_percentage=results.graphs.seniority.max_perc %} */}
              {<i dangerouslySetInnerHTML={{ __html: I18N._("Of all respondents <span>%(biggest_age_percentage)s%</span> had been in the school for <span>%(biggest_age)s</span>.", { params: { biggest_age: this.state.biggest_seniority, biggest_age_percentage: this.state.biggest_seniority_percentage } }) }}></i>}
              {/* {% endblocktrans %} */}
              {/* {% if results.graphs.home %} */}
              {/* {% blocktrans trimmed with biggest_home_situation_percentage=results.graphs.home.max_perc biggest_home_situation=results.graphs.home.max_label %} */}
              {this.state.biggest_home_situation_percentage && <i dangerouslySetInnerHTML={{ __html: I18N._("At home <span>%(biggest_home_situation_percentage)s%</span> of the respondents lived with <span>%(biggest_home_situation)s</span>.", { params: this.state }) }}></i>}
              {/* {% endblocktrans %} */}
              {/* {% endif %} */}
            </div>
          </div>
          <div className="row">
            {/* {# For the personnel #} */}
            {/* {% if not results.graphs.home and not results.graphs.lang %} */}
            {this.props.target_audience === 4 ?
              <ExpandableChart className="col-md-12" ChartClass={BarChart} vertical={false} params={this.state.seniorityChartParams} />
              :
              <>
                {/* {% else %} */}
                <ExpandableChart className="col-md-4" ChartClass={BarChart} vertical={true} params={this.state.langChartParams} />
                <ExpandableChart className="col-md-3" ChartClass={PieChart} vertical={false} params={this.state.seniorityChartParams} />
                <ExpandableChart className="col-md-5" ChartClass={BarChart} vertical={false} params={this.state.homeChartParams} />
              </>
            }
            {/* {% endif %} */}
          </div>
        </section >
      </div >
    )
  }
}

export default BasicAreaResults