import React from 'react';


export default class extends React.Component {
  state = {
    expanded: false
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  };

  render() {
    return (
      <div className={"choice-distribution diagram-widget" + (this.state.expanded ? " expanded" : "")}
        onClick={this.toggleExpanded}>
        <div className="bars">
          {
            this.props.count < 5 ?
              <div className="no-results"> {I18N._('There are no results')} </div>
              :
              this.props.bins.map((a, i) =>
                <div className={(i % 2 === 0 ? "even" : "odd") + " bar " + (a === 0 ? 'empty' : '')} key={i}
                  title={a + " answers for " + this.props.question.choices[i]}
                  style={{ width: a * 100 / this.props.count + '%' }}>

                  {this.props.question.choices[i]}

                  {
                    this.state.expanded &&
                    <div className="expanded-info">
                      {Math.round(a * 100 / this.props.count)}% ({a}) {this.props.question.choices[i]}
                    </div>
                  }
                </div>
              )
          }
        </div>
      </div>
    )
  }
}