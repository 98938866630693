import React from 'react';


export default class extends React.Component {
  state = {
    expanded: false
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  // can't calculate the average from just bin counts, would need it delivered from the backend
  // getAverage() {
  // this.props.values
  // }

  render() {
    // const answers = this.averageResults(this.props.answers, 5)

    return (
      <div className={"agree-disagree diagram-widget" + (this.state.expanded ? " expanded" : "")}
        onClick={this.toggleExpanded} data-testid="diagram">
        <div className={"bars" + (this.props.question.sem_rev === true ? " reversed" : "")}>
          {
            this.props.count < 5 ?
              <div className="no-results"> {I18N._('There are no results')} </div>
              :
              this.props.bins.map((a, i) =>
                <div className={"bar" + (i + 1) + " bar" + (a === 0 ? " empty" : "")} key={i}
                  title={a + ' ' + I18N._('answers') + ": " + this.props.question.choices[i]}
                  style={{ width: a / this.props.count * 100 + '%' }}
                  data-testid={"bar" + (i + 1)}>
                  {Math.round(a / this.props.count * 100) + '%'}

                  {
                    this.state.expanded &&
                    <div className="expanded-info">
                      {Math.round(a * 100 / this.props.count)}% ({a}) {this.props.question.choices[i]}
                    </div>
                  }
                </div>
              )
          }
        </div>
      </div>
    )
  }
}

