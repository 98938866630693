import AnswerRow from './components/v3/AnswerRow'
import AnswerRowAccordion from './components/v3/AnswerRowAccordion'
import SharedView from './components/v3/SharedView'
import { handle__react_renders } from './rendering'


const COMPONENTS = {
  AnswerRow,
  AnswerRowAccordion,
  SharedView
}

export default (opts) => {
  handle__react_renders(COMPONENTS);
}