require('expose-loader?exposes[]=$&exposes[]=jQuery!jquery')

//import log from 'expose-loader?exposes=log|default!./log.js';
import I18N from 'expose-loader?exposes=I18N._|default!./i18n';

// Importing the hooks used on the Django templates
import preregister from 'expose-loader?exposes=welp.preregister|default!./results/preregister.es6';
import report from 'expose-loader?exposes=welp.report|default!./results/report.jsx';
import report_form from 'expose-loader?exposes=welp.report_form|default!./results/report_form.jsx';
import new_form from 'expose-loader?exposes=welp.new_form|default!./results/new-form';
import sharing_view from 'expose-loader?exposes=welp.sharing_view|default!./results/sharing_view.jsx';

import sentry from './sentry'

// Polyfills
require('./polyfills.es6')
require('@babel/polyfill')
require('array.prototype.fill')
import 'whatwg-fetch'

require('./pygal-tooltips.min.js')

// styles
require('../styles/less/results-main.less')

$('.scroll-to-link').click(function (e) {
  var target = $(this).attr('href');
  var destination = $(target).offset().top;
  // var offset = ($('.sticky-bar').is(':visible') && $('.sticky-bar').position().top == 0) ?
  //   $('.sticky-bar').outerHeight(true) :
  //   0;
  const offset = 0;
  $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination - offset }, 400);
  return false; // prevents jump to top
});

