import React from 'react';

import ResultsArea from './ResultsArea';


const Results = ({ resultsSet, comparisons, mainSourceName, skipFirstArea }) => {
  return (
    <div>
      {
        resultsSet.results && resultsSet.results.map((props, i) => {
          if (skipFirstArea && i === 0) {
            return null
          }
          return <ResultsArea key={props.id}
            mainSourceName={mainSourceName}
            comparisons={comparisons}
            {...props} />
        }
        )
      }
    </div>
  )
}

export default Results