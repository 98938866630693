//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
}

export default function scrollTo(to, duration) {
  const start = window.scrollY,
    change = to - start,
    increment = 10
  let currentTime = 0

  const _animateScroll = function(){
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    if(currentTime < duration) {
      setTimeout(_animateScroll, increment)
    }
  }
  _animateScroll()
}