import React from 'react';
import { fetchComparisonData, fetchResultsData, ResultsDataContext, fillShortResultsToQuestionTree } from '../DataContext';

import Results from './Results';
import SchoolControls from './SchoolControls';
import FetchingDataBar from './FetchingDataBar';
import BasicAreaResults from './BasicAreaResults';
import SchoolResultsSemesterPicker from './SchoolResultsSemesterPicker';


const buildResultsUrl = (schoolId, targetAudience, args) => `/api/v1/results/school/${schoolId}/${targetAudience}?${args}`

const buildArguments = (controlsState) => {
  const separators = [];
  const locale = window.CURRENT_LOCALE || 'fi'
  const roundedSemester = controlsState.selectedSemester.split("-")[0]
  let args = `locale=${locale}&semester=${controlsState.selectedSemester}&format=short`
  let comparisonArgs = `locale=${locale}&semester=${roundedSemester}&format=short`

  if (controlsState.selectedView === "gradesSeparated") {
    separators.push("grade");
  }

  if (controlsState.separateGenders) {
    separators.push("sex");
  }

  if (separators.length) {
    args += `&separated_by=${separators.join(",")}`
    comparisonArgs += `&separated_by=${separators.join(",")}`
  }

  return [args, comparisonArgs]
}


export default class extends React.Component {

  static defaultProps = {
    school_id: null,
    city_name: null,
    city_slug: null,
    country_code: null,
    target_audience: null,
    available_semesters: null,
    questions_tree: null,
    my_schools: [],
    semester_start_year: null,
    unique_class_ids_with_counts: null
  };

  constructor(props) {
    super(props);

    this.state = {
      mainResults: null,
      currentMainArgs: null,
      fetchingMainData: true,
      fetchingComparisonData: false,
      changed: false,
      comparisons: []
    };

    this.exportView = this.exportView.bind(this)
    this.updateView = this.updateView.bind(this)
    this.fetchMainResults = this.fetchMainResults.bind(this)

    this.resultsDataContext = {
      freetextAnswers: {},
      fetchFreetextAnswers: () => { },
      showFreetextQuestions: false,
      openAccordionsInitially: false,
    }
  }

  exportView(controlsState) {
    const [mainArgs, comparisonArgs] = buildArguments(controlsState)
    const url = buildResultsUrl(this.props.school_id, this.props.target_audience, mainArgs) + "&output=excel"
    window.open(url, "_blank")
  }

  updateView(controlsState) {
    console.log("School.updateView()", controlsState);
    const [mainArgs, comparisonArgs] = buildArguments(controlsState)

    this.setState({
      fetchingMainData: true,
      changed: false
    })

    if (this.state.mainResults !== null || mainArgs !== this.state.currentMainArgs) {
      this.fetchMainResults(mainArgs)
    } else {
      console.log("No need to re-fetch main results")
      this.setState({ fetchingMainData: false })
    }

    // fetch the comparison data
    this.setState({ fetchingComparisonData: true })
    const comparisonPromises = controlsState.selectedComparisons.map(({ id }) => {
      if (id === "fi") {
        return fetchComparisonData("country", this.props.target_audience, "fi", comparisonArgs, true)
      } if (id === "city") {
        return fetchComparisonData("city", this.props.target_audience, this.props.city_slug, comparisonArgs, true)
      } else if (id === "last_year") {
        const comparisonData = `school,${this.props.school_id},${parseInt(this.props.semester.split("-")[0]) - 1}`
        return fetchComparisonData("last_year", this.props.target_audience, comparisonData, comparisonArgs, true)
      } else {
        return fetchComparisonData("school", this.props.target_audience, parseInt(id), comparisonArgs, true)
      }
    })

    Promise.all(comparisonPromises).then((resolvedResults) => {
      this.setState({
        fetchingComparisonData: false
      })
      const results = resolvedResults.map((results) => {
        const school = this.props.my_schools.find(({ id }) => id === results.queryId)
        // get the name for the comparison
        let schoolName = ""
        if (school) {
          schoolName = school.name
        } else if (results.comparisonType === "country") {
          schoolName = I18N._("country")
        } else if (results.comparisonType === "last_year") {
          schoolName = I18N._("Last year")
        } else if (results.comparisonType === "city") {
          schoolName = this.props.city_name ? this.props.city_name : this.props.city_slug
        }
        if (!results.id) {
          results.id = results.queryId
        }
        return { schoolName, ...results }
      })
      this.setState({ fetchingComparisonData: false, comparisons: results })
    })

  }

  fetchMainResults(args) {
    fetchResultsData(buildResultsUrl(this.props.school_id, this.props.target_audience, args))
      .then((obj) => {
        obj.results = fillShortResultsToQuestionTree(this.props.questions_tree, obj.results)
        this.setState({
          mainResults: obj,
          currentMainArgs: args,
          fetchingMainData: false
        })
      }).catch(err => {
        this.setState({
          fetchingMainData: false,
          fetchingComparisonData: false
        })
      })
  }

  render() {

    const comparisons = this.state.comparisons
      .filter(({ error }) => error !== true)
      .map((comparison) => {
        return {
          data: comparison,
          with: comparison.schoolName,
          name: comparison.schoolName
        }
      })

    const loadingCompleted = !(this.state.fetchingMainData || this.state.fetchingComparisonData)

    return (
      <ResultsDataContext.Provider value={this.resultsDataContext}>

        <SchoolResultsSemesterPicker chosenSemester={this.props.semester} semesters={this.props.available_semesters} results_url_template={this.props.results_url_template} />

        {this.state.mainResults !== null && <BasicAreaResults mainResults={this.state.mainResults} {...this.props} />}

        <SchoolControls {...this.props} comparisons={this.state.comparisons} updateCallback={this.updateView} excelExportCallback={this.exportView} ready={loadingCompleted} />

        {
          loadingCompleted ?
            <>
              {this.state.mainResults !== null &&
                <Results
                  resultsSet={this.state.mainResults}
                  comparisons={comparisons}
                  mainSourceName={I18N._("us")}
                  skipFirstArea={true}
                />
              }
            </>
            :
            <FetchingDataBar />
        }

      </ResultsDataContext.Provider>
    )
  }
}