import React from 'react';

require('../../../styles/less/components/extra-questions-widget.less');

export default class extends React.Component {
  state = { questions: [] };

  addBlank = () => {
    let qs = this.state.questions;
    this.setState({ questions: qs.concat({ id: new Date().getTime(), text: '', type: 'SO' }) });
  };

  addQuestion = (text, type) => {
    let qs = this.state.questions;
    this.setState({ questions: qs.concat({ id: new Date().getTime(), text, type }) });
  };

  removeQuestion = (id) => {
    let qs = this.state.questions.slice().filter((qq) => qq.id !== id);
    this.setState({ questions: qs });
  };

  render() {
    return (
      <div className="extra-questions-widget">
        {
          this.state.questions.map((q) =>
            <Question removeCb={this.removeQuestion} key={q.id} id={q.id} text={q.text} type={q.type} />)
        }
        {
          <div className="form-group">
            <div className="col-xs-12 bottom-buttons">
              <a className="btn btn-success btn-sm add-new-question-button" onClick={this.addBlank}>
                {I18N._('Add a new question')}
                <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>
              </a>
            </div>
          </div>
        }
      </div>
    )
  }
}

class Question extends React.Component {
  state = { question: this.props.text || '', type: this.props.type || null, changed: false };

  changeQuestion = (e) => {
    this.setState({ question: e.target.value, changed: true });
  };

  changeType = (e) => {
    this.setState({ type: e.target.value, changed: true });
  };

  render() {
    return (
      <div className="question">
        <div className="form-group">
          <div className="col-sm-6 col-xs-12">
            <input className="form-control" name="extra_question_texts[]" type="text" onChange={this.changeQuestion}
              defaultValue={this.props.text} maxLength="200" />
            <span className="help-block">Max. 200 chars</span>
          </div>
          <div className="col-sm-4 col-xs-12">
            <select className="form-control" name="extra_question_types[]" onChange={this.changeType} defaultValue={this.props.type}>
              <option value="SC">{I18N._('Fully agree to fully disagree')}</option>
              <option value="FT">{I18N._('Free text')}</option>
            </select>
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="btn-toolbar">
              <div className="btn-group">
                {
                  this.props.removeCb &&
                  <a className="btn btn-danger" onClick={() => this.props.removeCb(this.props.id)}>
                    <span className="glyphicon glyphicon-trash" aria-hidden="true"></span>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}