import React from 'react';

import SemesterChooser from './SemesterChooser';


export default class extends React.Component {

  static defaultProps = {
    semesters: [],
    results_url_template: "",
    chosenSemester: null
  };

  constructor(props) {
    super(props);
    this.state = {
      chosenSemester: props.chosenSemester ?
        props.chosenSemester :
        (props.semesters.length > 0 ? props.semesters[props.semesters.length - 1][0] : null)
    }

    this.semesterChosen = this.semesterChosen.bind(this)
    this.open = this.open.bind(this)
  }

  semesterChosen(event) {
    event.preventDefault()
    const value = event.target.value
    this.setState({ chosenSemester: value })
  }

  open(event) {
    event.preventDefault()
    const url = this.props.results_url_template.replace("SEMESTER", this.state.chosenSemester)
    // console.log(url, this.props, this.state)
    window.location.href = url
  }

  render() {
    return (
      <header>
        <h2>{I18N._("Semester")}</h2>
        {
          this.props.semesters.length > 0 ?
            <div className="semester-dropdown-and-button">
              <SemesterChooser name=""
                value={this.state.chosenSemester}
                onChange={(evt) => this.semesterChosen(evt)}
                availableSemesters={this.props.semesters} />
              <button className="btn btn-sm btn-success" onClick={(evt) => this.open(evt)}>{I18N._("Open")}</button>
            </div>
            :
            <div className="action no-results">
              {I18N._("There are no results")}
            </div>
        }
      </header >
    )
  }
}
