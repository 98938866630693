import React from 'react';
import { getSemesterName } from '../../welp.utils';

const SemesterOption = ({ semester, count }) => {
  const parts = semester.split("-")
  let title = `${semester} (${count})`
  if (parts.length > 1) {
    title = `\u00A0\u00A0\u00A0\u00A0${getSemesterName(semester)} (${count})`
  }
  return <option className={parts.length > 1 ? "semester" : "year"} value={semester}>{title}</option>
}

export default ({ name, value, availableSemesters, onChange }) =>
  <select name={name} className="form-control semester-chooser" onChange={onChange}
    defaultValue={value}>
    {
      availableSemesters.map(([semesterIdentifier, answerSetAmount]) =>
        <SemesterOption semester={semesterIdentifier} key={semesterIdentifier} count={answerSetAmount} />
      )
    }
  </select>