import React from 'react';

import AgreeDisagreeDistribution from './diagrams/AgreeDisagreeDistribution'
import ChoiceDistribution from './diagrams/ChoiceDistribution'

const DIAGRAMS_TO_TYPES = {
  'SC': AgreeDisagreeDistribution,
  'SO': ChoiceDistribution
};


const AnswerRow = ({ question, rowName, bins, count, showAmounts }) => {

  const Diagram = DIAGRAMS_TO_TYPES[question.type]
  // Count won't be defined for averaging subcategory calculations
  // let's calculate it here in that case. If there are too few answers the sum
  // will be 0 (handled already by backend)
  if (!count) {
    count = bins.reduce((acc, val) => acc + val, 0)
  }
  const diagramProps = { question, bins, count }

  return (
    <div className="answer-row">
      {
        rowName &&
        <div className="name" title={rowName}>
          {rowName}
        </div>
      }
      {
        showAmounts &&
        <div className="amount" title={count + " " + I18N._('answers')}>
          {count}
        </div>
      }
      {
        count < 5 ?
          <div className="no-results"> {I18N._('There are no results')} </div>
          :
          <div className="graph">
            {typeof Diagram !== 'undefined' ? <Diagram {...diagramProps} /> : null}
          </div>
      }
    </div>
  )
}

AnswerRow.defaultProps = {
  question: {},
  bins: [],
  showAmounts: true,
}

export default AnswerRow

