if (typeof (String.prototype.trim) === "undefined") {
  String.prototype.trim = function () {
    return String(this).replace(/^\s+|\s+$/g, '');
  };
}

// Loading animation related stuff. Used in global and local
var LOADERBAR = null;
var LOADERINFO = null;

// set the percentage of loaded and info
export function setLoadingInfo(perc, info) {
  if (!LOADERBAR || !LOADERINFO) {
    LOADERBAR = $('#loader');
    LOADERINFO = $('#loader-info');
  }
  if (perc == null) {
    LOADERBAR.hide();
    LOADERINFO.text(info);
    _loadingInfoFader();
  } else {
    LOADERBAR.show();
    perc = perc * 100;
    LOADERINFO.text(info + ': ' + parseInt(perc) + '%');
    LOADERINFO.css('width', perc + '%').attr('aria-valuenow', perc);
  }
}

function _loadingInfoFader() {
  LOADERINFO.fadeOut('slow', function () {
    LOADERINFO.fadeIn('slow', function () {
      if ($('#loader').css('display') == 'block' &&
        LOADERBAR.parent().css('display') == 'none') {
        _loadingInfoFader();
      } else {
        LOADERINFO.show();
      }
    });
  });
}

/* csrf protection in django */
function csrfSafeMethod(method) {
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
function sameOrigin(url) {
  var host = document.location.host; // host + port
  var protocol = document.location.protocol;
  var sr_origin = '//' + host;
  var origin = protocol + sr_origin;
  return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
    (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') || !(/^(\/\/|http:|https:).*/.test(url));
}

// Setup CSRF protection required by Django and generally a "good thing"
export default function setupCSRFProtection() {
  var csrftoken = $.cookie('csrftoken');
  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
      }
    }
  });
}


// Some colors for charts, etc.
const darkGreen = "#14945f"
const lightGreen = "#85b63c"
const red = "#e03537"
const yellow = "#ebd243"
const orange = "#e35d31"
export const DEFAULT_COLORS = {
  'country': orange,
  'city': darkGreen,
  'default': lightGreen,
}

// start slightly adapted from LightGreenStyle of pygal..
export const COLOR_LIST = [
  '#7dcf30',
  '#247fab',
  '#97fa3a',
  '#ccc',
  '#5e9c23',
  '#ddd',
  '#2ea6e2',
  '#165270',
  yellow,
  "#ebb043",
  "#7e43eb",
  "#eb8f43",
  red,
  "#4643eb",
  "#eb7343",
  "#cf43eb",
  "#eb4943",
  yellow,
  "#ebb043",
  "#7e43eb",
  "#eb8f43",
  red,
  "#4643eb",
  "#eb7343",
  "#cf43eb",
  "#eb4943",
]


// Hardcoded names will fail in more exotic setups, but should
// be easy to remedy once there
const SEMESTER_NAMES = ["Autumn", "Spring"]

export function getSemesterName(semester) {
  const parts = semester.split("-")
  let title = `${semester}`
  if (parts.length > 1) {
    // Another place where the indexes are hard coded to mean something
    // add a year if dealing with spring (as it's one year more)
    const year = parseInt(parts[1]) == 1 ? parseInt(parts[0]) + 1 : parts[0]
    title = `${I18N._(SEMESTER_NAMES[parseInt(parts[1])])} ${year}`
  }
  return title
}


/** Determine a comparison name in relation to the main query */
export function determineComparisonName(comparison, main_query_spec) {
  if (main_query_spec.type == "city") {
    if (comparison.type == "city") {
      return getSemesterName(comparison.semester)
    }
  } else {
    if (comparison.type == "form") {
      return getSemesterName(comparison.semester)
    }
  }
  return I18N._(comparison.type)
}
