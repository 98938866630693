import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { getSemesterName, determineComparisonName } from '../../welp.utils'


const SharedViewTableHeading = ({ withId }) => {
  return (
    <thead>
      <tr>
        {withId && <th> {I18N._("View id")} </th>}
        <th> {I18N._("Semester")} </th>
        <th> {I18N._("View")} </th>
        <th> {I18N._("Genders separated")} </th>
        <th> {I18N._("Comparison")} </th>
      </tr>
    </thead>
  )
}

const copyText = (elementId, ev) => {
  ev && ev.preventDefault()
  const el = document.getElementById(elementId)
  el.select()
  document.execCommand("copy")
  let btn = ev.target
  if (btn.nodeName === "SPAN") {
    btn = btn.parentNode
  }
  btn.classList.add("copied")
  setTimeout(() => {
    btn.classList.remove("copied")
  }, 2000)
}

const SharedViewTableRow = ({ id, semester, view, gendersSeparated, comparisons, deleteCallback }) => {

  const viewName = I18N._(view)

  let comparisonName = I18N._("none")
  if (typeof comparisons == "string") {
    comparisonName = comparisons
  } else if (typeof comparisons == "object" && comparisons.map !== undefined) {
    comparisonName = comparisons.map(c => I18N._(c)).join(", ")
  }

  return (
    <tr>
      {id &&
        <td>
          <input readOnly className="shared-link-text" type="text" id={`shared-link-${id}`} name="link-for-shared-view" value={`https://koulunhyvinvointiprofiili.fi/s/${id}`} />
          <button className="btn btn-xs copy-button" title={I18N._("Copy link to clipboard")} onClick={(ev) => copyText(`shared-link-${id}`, ev)}>
            <span className="glyphicon" aria-hidden="true">📋</span>
          </button>
        </td>
      }
      <td>{getSemesterName(semester)}</td>
      <td>{viewName}</td>
      <td>{gendersSeparated ? I18N._("Yes") : I18N._("No")}</td>
      <td>{comparisonName}</td>
      {
        id &&
        <td>
          <div className="btn-toolbar">
            <a className="btn btn-xs btn-primary" href={`/s/${id}`} target="_blank">{I18N._("Open")}</a>
            <button className="btn btn-xs btn-danger" onClick={(ev) => deleteCallback(ev, id)}>{I18N._("Delete")}</button>
          </div>
        </td>
      }
    </tr>
  )
}

const createSharedView = (view, callback, ev) => {
  ev && ev.preventDefault()
  let filter_grade = null
  let filter_class_id = null
  let separated_by = view.gendersSeparated ? ["sex"] : []
  if (view.view == "gradesSeparated") {
    separated_by.push("grade")
  } else if (view.view.indexOf(",") !== -1) {
    const [grade, classId] = view.view.split(',')
    filter_grade = grade
    filter_class_id = classId
  }

  const querySpec = {
    semester: view.semester,
    comparisons: view.comparisons ? view.comparisons : null,
    separated_by,
    filter_class_id,
    filter_grade,
  }

  const csrftoken = Cookies.get('csrftoken')
  const shareUrl = view.citySlug ? `/api/v1/results/shared-views/city/${view.citySlug}/${view.targetAudience}` : `/api/v1/results/shared-views/form/${view.formId}`
  fetch(shareUrl,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify(querySpec),
    })
    .then(response => response.json())
    .then(obj => {
      if (obj.error) {
        alert("Fail: " + JSON.stringify(obj))
      } else {
        console.log("Success!")
        callback(querySpec)
      }
    })
    .catch(err => {
      console.error(err)
      alert("Error requesting data from backend: " + err)
    })
  return false
}

const deleteSharedView = (id, callback) => {
  const csrftoken = Cookies.get('csrftoken')
  const shareUrl = `/api/v1/results/shared-views/${id}`
  fetch(shareUrl,
    {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': csrftoken,
      }
    })
    .then(response => response.json())
    .then(obj => {
      if (obj.error) {
        alert("Failed to delete shared view: " + obj.msg)
      } else {
        console.info("Success with delete")
        callback && callback(obj)
      }
    })
    .catch(err => {
      console.error(err)
      alert("Error deleting view from backend: " + err)
    })
}


const extractSharedViewProps = ({ id, created_by, view_config }) => {
  console.log("extractSharedViewProps()", id, created_by, view_config)
  const gendersSeparated = view_config.main_query_spec.separated_by.indexOf("sex") !== -1
  const comparisons = view_config.comparison_query_specs.map((c) => determineComparisonName(c, view_config.main_query_spec)).join(", ")
  const semester = `${view_config.main_query_spec.semester}`
  const gradesSeparated = view_config.main_query_spec.separated_by.indexOf("grade") !== -1
  let view = "all"
  if (gradesSeparated === true) {
    view = "gradesSeparated"
  } else if (view_config.main_query_spec.class_id && view_config.main_query_spec.grade) {
    view = `${view_config.main_query_spec.grade}, ${view_config.main_query_spec.class_id}`
  }
  return {
    id,
    created_by,
    semester,
    view,
    gendersSeparated,
    comparisons
  }
}

const getSharedViewsForm = (formId, callback) => {
  const shareUrl = `/api/v1/results/shared-views/form/${formId}`
  _getSharedViews(shareUrl, callback)
}


const getSharedViewsCity = (citySlug, targetAudience, callback) => {
  const shareUrl = `/api/v1/results/shared-views/city/${citySlug}/${targetAudience}`
  _getSharedViews(shareUrl, callback)
}

const _getSharedViews = (shareUrl, callback) => {
  console.log("_getSharedViews with", shareUrl)
  fetch(shareUrl,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(obj => {
      if (obj.error) {
        alert("Fail: " + JSON.stringify(obj))
      } else {
        console.log("shared views...", obj.shared_views);
        const properProps = obj.shared_views.map(extractSharedViewProps)
        callback(properProps)
      }
    })
    .catch(err => {
      console.error(err)
      alert("Error requesting data from backend: " + err)
    })
}


export default ({ currentView, closeCallback }) => {

  const [sharedViews, setSharedViews] = useState([])

  const refreshSharedViews = currentView.citySlug ?
    () => getSharedViewsCity(currentView.citySlug, currentView.targetAudience, (res) => setSharedViews(res)) :
    () => getSharedViewsForm(currentView.formId, (res) => setSharedViews(res))


  useEffect(() => {
    refreshSharedViews()
  }, [])

  const deleteView = (ev, code) => {
    ev.preventDefault()
    if (confirm(I18N._("Are you sure you wish to delete this shared view?"))) {
      deleteSharedView(code, refreshSharedViews)
    }
    return false;
  }

  let unsupportedSharingWarning = null
  if (currentView.comparisons.includes("school") || currentView.comparisons.includes("time")) {
    unsupportedSharingWarning = I18N._("Sharing comparisons with school or past semester are not supported.")
  }

  console.log("shared views we gots", sharedViews)

  return (
    <div className="modal show sharing-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">

            <h2> {I18N._("View sharing")} </h2>

            <p>
              {I18N._("Create a shareable URL of the currently open results. This URL can be accessed without logging in. Good for sharing a limited view to the results for parents, teachers or other stakeholders.")}
            </p>

            {sharedViews.length > 0 &&
              <>
                <h4>
                  {I18N._("Currently shared views")}
                </h4>
                <table>
                  <SharedViewTableHeading withId={true} />
                  <tbody>
                    {sharedViews.map((props) => (
                      <SharedViewTableRow key={props.id} {...props} deleteCallback={deleteView} />
                    ))}
                  </tbody>
                </table>
              </>
            }

            <h4> {I18N._("Share the current view")} </h4>
            <table>
              <SharedViewTableHeading />
              <tbody>
                <SharedViewTableRow {...currentView} />
              </tbody>
            </table>
            {unsupportedSharingWarning && <p class="errorlist">{unsupportedSharingWarning}</p>}

          </div>
          <div className="modal-footer">
            <div className="btn-toolbar pull-right">
              <button className="btn btn-warning" onClick={() => closeCallback()}>
                {I18N._("Close")}
              </button>
              <button className="btn btn-success" onClick={(ev) => createSharedView(currentView, refreshSharedViews, ev)}>
                {I18N._("Share")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
