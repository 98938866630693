import React from 'react'


const _comparisonResultDataCache = {}


export const fillShortResultsToQuestionTree = (tree, answers) => {
    const combinedTree = tree.reduce((col, category) => {
        const newSubcategories = category.subcategories.reduce((scol, subcategory) => {
            const newQuestions = subcategory.questions.reduce((qcol, question) => {
                question.answers = answers[question.question.id]
                qcol.push(question)
                return qcol
            }, [])
            subcategory.questions = newQuestions
            scol.push(subcategory)
            return scol
        }, [])
        category.subcategories = newSubcategories
        col.push(category)
        return col
    }, [])
    return combinedTree
}

export const fetchComparisonData = (comparisonType, targetAudience, comparisonId, args, dontAlertOnError) => {
    let url = null;
    if (comparisonType === "class") {
        const [form, grade, classId] = comparisonId.split(",")
        if (grade) {
            args += `&filter_grade=${grade}`
        }
        if (classId) {
            args += `&filter_class_id=${classId}`
        }
        url = `/api/v1/results/form/${form}?${args}`;
    }
    else if (comparisonType === "last_year") {
        const [comparisonType, newComparisonId, lastSemester] = comparisonId.split(",")
        args = args.replace(/semester=[0-9]+/, `semester=${lastSemester}`)
        url = `/api/v1/results/${comparisonType}/${newComparisonId}/${targetAudience}?${args}`;
    }
    else {
        url = `/api/v1/results/${comparisonType}/${comparisonId}/${targetAudience}?${args}`;
    }

    if (_comparisonResultDataCache[url] !== undefined) {
        console.log("DataContext: we already have", comparisonType, "with", args, "> not fetching");
        return Promise.resolve(_comparisonResultDataCache[url])
    } else {
        console.log("DataContext: fetch comparison", url);
        return fetchResultsData(`${url}&format=short`, dontAlertOnError, { comparisonType: comparisonType, queryId: comparisonId })
            .then((obj) => {
                _comparisonResultDataCache[url] = obj
                return obj
            })
    }
}

export const fetchResultsData = (url, dontAlertOnError, context) => {
    return fetch(url,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
        })
        .then(response => response.json())
        .then(obj => {
            if (context) {
                obj = Object.assign(obj, context)
            }
            if (obj.error) {
                if (obj.msg === "Not enough answers") {
                    console.warn("TOO FEW ANSWERS")
                    !dontAlertOnError && alert(I18N._("Too few answers provided"))
                } else {
                    !dontAlertOnError && alert("Fail: " + JSON.stringify(obj))
                }
            }
            return obj
        })
        .catch(err => {
            console.error(err)
            alert("Error requesting data from backend: " + err)
        })
}

export const fetchFreetextAnswers = (formId, questionId, semesterStartYear, args) => {
    return fetch(`/api/v1/results/freetext/form/${formId}/${questionId}/${semesterStartYear}?${args}`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
        })
        .then(response => response.json())
        .then(obj => {
            if (obj.error) {
                if (obj.msg === "Not enough answers") {
                    return obj
                } else {
                    alert("Fail: " + JSON.stringify(obj))
                }
            } else {
                console.log("Success with free text answers!")
                return obj
            }
        })
        .catch(err => {
            console.error(err)
            alert("Error requesting data from backend: " + err)
        })
}

export const ResultsDataContext = React.createContext(
    {
        freetextAnswers: {},
        currentMainArgs: null,
        fetchFreetextAnswers: () => { },
        showFreetextQuestions: false,
        openAccordionsInitially: true,
        // a running number for the amount of fetches within a session that can be used to key stale components
        counter: 0
    }
)