import React from 'react';
import { useState } from 'react';

import SharingModal from './SharingModal';
import SemesterChooser from './SemesterChooser';

const ViewChooser = ({ name, value, availableClasses, onChange }) =>
  <select name={name} className="form-control" onChange={onChange}
    defaultValue={value}>
    <option value="all">{I18N._("All")}</option>
    <option value="gradesSeparated">{I18N._("Grade levels separated")}</option>
    <option value="" disabled>{I18N._("Limit to class")}</option>
    {
      availableClasses.map(([classId, gradeAndId]) =>
        <option value={gradeAndId} key={gradeAndId}>{classId}</option>
      )
    }
  </select>


const ExportButtons = ({ semester, classFilter, controlsState, formId, school }) => {
  const [showSharingModal, setShowSharingModal] = useState(false)
  const url = `/answers/view/xlsx/${formId}/${semester}`
  const filter = classFilter !== "all" && classFilter !== "gradesSeparated" ? `?filter_class=${classFilter}` : ""

  const closeCallback = (querySpecs) => {
    setShowSharingModal(false)
  }

  const currentView = {
    semester: semester,
    comparisons: [controlsState.compareWith],  // here limiting to just one...
    view: controlsState.selectedView,
    gendersSeparated: controlsState.separateGenders,
    formId
  }

  return <div className="export-buttons">
    <a href={url + filter} target="_blank" className="btn btn-default export-button" title={I18N._("Excel (XLSX) spreadsheet format")}>
      <span className="xls-emblem"></span>
    </a>
    <a className="btn btn-default" onClick={() => setShowSharingModal(true)} title={I18N._("Share the current view")}>
      <span className="glyphicon glyphicon-share" aria-hidden="true"></span>
    </a>
    {showSharingModal &&
      <SharingModal
        currentView={currentView}
        closeCallback={closeCallback}
      />
    }
  </div>
}


const readInitialParams = (props) => {
  let selectedSemester = props.available_semesters[0][0]

  const params = window ? window.location.search : ""
  const cleaned = params.startsWith('?') ? params.substr(1) : params
  const pairs = cleaned.split('&')
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=')
    if (key === "semester") {
      console.info("Found semester set in GET params", value);
      selectedSemester = value
    }
  })

  return {
    selectedSemester: selectedSemester,
    selectedView: "all",
    separateGenders: false,
    separateGrades: false,
    compareWith: "none",
    changed: false
  }
}


export default class Controls extends React.Component {

  static defaultProps = {
    semester: null,
    available_semesters: [],
    available_classes: [],
    school: {},
    form: {},
    updateCallback: null
  };

  constructor(props) {
    super(props);

    this.state = readInitialParams(props)

    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateView = this.updateView.bind(this);
    this.recheckComparison = this.recheckComparison.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedSemester) {
      this.updateView()
    }
  }

  recheckComparison(name, value) {
    if (name === "selectedView") {
      if (value === "all" || value === "gradesSeparated" && this.state.compareWith === "class") {
        // reset to none if attempting to select comparison with class without class selected
        return "none";
      }
    } else if (name === "compareWith") {
      return value
    }
    return this.state.compareWith;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const validatedComparison = this.recheckComparison(name, value);
    console.log("handleInputChange", name, value)
    this.setState({
      [name]: value,
      compareWith: validatedComparison,
      changed: true
    });
  }

  updateView(event) {
    event && event.preventDefault();
    this.props.updateCallback(this.state)
  }


  render() {

    return (
      <>
        <form className="row form-control-panel" onSubmit={() => false}>
          <div className="col-sm-3">
            <h4> {I18N._("Semester")}: </h4>
            <div className="form-group">
              <SemesterChooser
                name="selectedSemester"
                value={this.state.selectedSemester}
                onChange={this.handleInputChange}
                availableSemesters={this.props.available_semesters} />
            </div>
          </div>

          <div className="col-sm-3">
            <h4> {I18N._("View")}: </h4>
            {this.props.form.target_audience !== 4 &&
              <>
                <div className="form-group">
                  <ViewChooser
                    name="selectedView"
                    value={this.state.selectedView}
                    onChange={this.handleInputChange}
                    availableClasses={this.props.available_classes} />
                </div>
              </>
            }
            <div className="checkbox">
              <label>
                <input type="checkbox"
                  name="separateGenders"
                  onChange={this.handleInputChange}
                  checked={this.state.separateGenders} />
                {I18N._("Genders separated")}
              </label>
            </div>
          </div>

          <div className="col-sm-3">
            <h4> {I18N._("Compare with")}: </h4>
            <div className="radio">
              <label>
                <input name="compareWith" type="radio" value="none" onChange={this.handleInputChange}
                  checked={this.state.compareWith === "none"} />
                {I18N._("No comparison")}
              </label>
            </div>

            <div className="radio">
              <label>
                <input name="compareWith" type="radio" value="last_year" onChange={this.handleInputChange}
                  checked={this.state.compareWith === 'last_year'} />
                {I18N._("Last year")}
              </label>
            </div>

            {this.props.school.city_slug &&
              <div className="radio">
                <label>
                  <input name="compareWith" type="radio" value="city" onChange={this.handleInputChange}
                    checked={this.state.compareWith === 'city'} />
                  {I18N._("City results")}
                </label>
              </div>
            }

            <div className="radio">
              <label>
                <input name="compareWith" type="radio" value="country" onChange={this.handleInputChange}
                  checked={this.state.compareWith === 'country'} />
                {I18N._("Country results")}
              </label>
            </div>

            <div className="radio with-select">
              <label className={(this.state.selectedView === "all" || this.state.selectedView === "gradesSeparated") ? "disabled" : ""}>
                <input name="compareWith" type="radio" value="class" onChange={this.handleInputChange}
                  checked={this.state.compareWith === 'class'}
                  disabled={this.state.selectedView === "all" || this.state.selectedView === "gradesSeparated"} />
                {I18N._("Single class")}

                <select name="compareWithClass" className="form-control" onChange={this.handleInputChange}
                  defaultValue={null} disabled={this.state.compareWith !== 'class'}>
                  {
                    this.props.available_classes.map(([classId, gradeAndId]) =>
                      <option value={gradeAndId} key={gradeAndId}>{classId}</option>
                    )
                  }
                </select>
              </label>
            </div>
          </div>

          <div className="col-sm-2 submit">
            <button className={'btn btn-primary'}
              onClick={this.updateView}
              disabled={!this.state.changed}
            >{I18N._("Update")}</button>
          </div>

          <div className="col-sm-1">
            <h4> {I18N._("Share")}: </h4>
            <ExportButtons
              formId={this.props.form.id}
              semester={this.state.selectedSemester}
              school={this.props.school}
              classFilter={this.state.selectedView}
              controlsState={this.state}
            />
          </div>

        </form>
      </>
    )
  }
}
