import React from 'react';

export default class extends React.Component {
  static defaultProps = { question_areas: [] };

  constructor(props) {
    super(props)
    this.state = {
      selectedAreas: props.question_areas.map((area) => area.id)
    }
    this.toggleSelect = this.toggleSelect.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question_areas !== this.props.question_areas) {
      this.setState({
        selectedAreas: this.props.question_areas.map((area) => area.id)
      })
    }
  }

  toggleSelect(qaId) {
    let newSelected = this.state.selectedAreas.slice()
    if (this.state.selectedAreas.includes(qaId)) {
      newSelected = newSelected.filter((id) => id !== qaId)
    } else {
      newSelected.push(qaId)
    }
    this.setState({ selectedAreas: newSelected })
  }

  render() {
    const totalQuestions = this.props.question_areas
      .filter((qa) => this.state.selectedAreas.includes(qa.id))
      .reduce((col, area) => area.questions.length + col, 0);
    return (
      <div className="form-group even vertical-padding question-areas-container">
        <div className="col-sm-4 col-xs-12">
          <label className="control-label">
            {I18N._("Included areas")}
          </label>
          <p className="help-block">
            <span dangerouslySetInnerHTML={{ __html: I18N._("There are <span>%(questions_in_total)s</span> questions in total", { params: { questions_in_total: totalQuestions } }) }}>
            </span>.
            < br />
            < br />
            {I18N._("Including all question areas is recommended")}
          </p>
        </div>
        {
          this.props.question_areas.length > 0 ?
            <div className="col-sm-8 col-xs-12 question-areas">
              {
                this.props.question_areas.map((qa) =>
                  <NewFormQuestionArea
                    key={qa.id}
                    qa={qa}
                    selected={this.state.selectedAreas.includes(qa.id)}
                    onToggleSelect={() => this.toggleSelect(qa.id)} />
                )
              }
            </div> :
            <div className="col-sm-8 col-xs-12 question-areas">
              {I18N._("loading questions")}
            </div>
        }
      </div >
    )
  }
}

class NewFormQuestionArea extends React.Component {
  state = { expanded: false };

  toggleExpanded = (e) => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
    return false;
  };

  render() {
    const qa = this.props.qa
    return (
      <label key={qa.id}
        className={"col-xs-12 form-group " + (qa.mandatory ? 'mandatory' : '')}>
        <input
          type="checkbox"
          id={"question-area-" + qa.id}
          name={"question_areas#" + qa.id}
          checked={this.props.selected}
          onChange={this.props.onToggleSelect}
          disabled={(qa.mandatory ? 'mandatory' : '')}
        />
        {qa.category}
        <a href="#" onClick={this.toggleExpanded}>
          {qa.questions.length} {I18N._("questions")}
          <span
            className={"glyphicon glyphicon-chevron-" + (this.state.expanded ? "up" : "down")}
            aria-hidden="true">

          </span>
        </a>
        <span>
          {(qa.mandatory ? I18N._("always included") : '')}
        </span>
        {
          this.state.expanded ?
            <ul className="questions-list">
              {
                qa.questions.map((q) =>
                  <li key={q.id}>{q.text}</li>
                )
              }
            </ul>
            : ''
        }
      </label >
    )
  }
}
