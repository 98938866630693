import AnswerRow from './components/v3/AnswerRow'
import AnswerRowAccordion from './components/v3/AnswerRowAccordion'
import FormResults from './components/v3/FormResults'
import SchoolAudiencePicker from './components/v3/SchoolAudiencePicker'
import SchoolFormPicker from './components/v3/SchoolFormPicker'
import { handle__react_renders } from './rendering'


const COMPONENTS = {
  AnswerRow,
  AnswerRowAccordion,
  FormResults,
  SchoolAudiencePicker,
  SchoolFormPicker,
}

export default (opts) => {
  handle__react_renders(COMPONENTS);
}
