import React from 'react';
import { fetchComparisonData, fetchFreetextAnswers, fetchResultsData, ResultsDataContext } from '../DataContext';

import Results from './Results';
import FormControls from './FormControls';
import FetchingDataBar from './FetchingDataBar';


const COMPARISON_NAMES = {
  "last_year": "Last year",
  "school": "school",
  "city": "city",
  "country": "country"
}

const buildResultsUrl = (formId, args) => `/api/v1/results/form/${formId}?${args}`

const buildArguments = (controlsState) => {
  const separators = [];
  const locale = window.CURRENT_LOCALE || 'fi'
  const roundedSemester = controlsState.selectedSemester.split("-")[0]
  let args = `locale=${locale}&semester=${controlsState.selectedSemester}`
  let comparisonArgs = `locale=${locale}&semester=${roundedSemester}`

  if (controlsState.selectedView === "gradesSeparated") {
    separators.push("grade");
  }
  else if (controlsState.selectedView !== "all") {
    const [grade, classId] = controlsState.selectedView.split(",")
    if (grade) {
      args += `&filter_grade=${grade}`
      comparisonArgs += `&filter_grade=${grade}`
    }
    if (classId) {
      args += `&filter_class_id=${classId}`
    }
  }

  if (controlsState.separateGenders) {
    separators.push("sex");
  }

  if (separators.length) {
    args += `&separated_by=${separators.join(",")}`
    comparisonArgs += `&separated_by=${separators.join(",")}`
  }

  return [args, comparisonArgs]
}

const sourceNameFromFilters = (filters) =>
  filters["grade"] ?
    `${filters["grade"]}${filters["class_id"] !== null ? filters["class_id"] : ""}` :
    null



export default class extends React.Component {

  static defaultProps = {
    available_semesters: [],
    available_classes: [],
    school: {},
    form: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      currentMainArgs: null,
      fetchingMainData: false,
      fetchingComparisonData: false,
      compareWith: "none",
      comparisonResults: { city: null, country: null, class: null },
      mainResults: null,
      changed: false,
      freetextAnswers: {},
      currentSemester: null,
      resultsFetchingCounter: 0
    };

    this.updateView = this.updateView.bind(this);
    this.fetchMainResults = this.fetchMainResults.bind(this);
    this.fetchComparisonResults = this.fetchComparisonResults.bind(this);
    this.getResultsDataContext = this.getResultsDataContext.bind(this);
  }

  getResultsDataContext() {
    return {
      showFreetextQuestions: true,
      freetextAnswers: this.state.freetextAnswers,
      currentMainArgs: this.state.currentMainArgs,
      requestFreetextAnswers: (questionId) => {
        const args = this.state.currentMainArgs
        fetchFreetextAnswers(this.props.form.id, questionId, this.state.currentSemester, args).then((values) => {
          if (values.error) {
            this.state.freetextAnswers[questionId] = values.msg
          }
          else if (values) {
            this.state.freetextAnswers[questionId] = values.answers
          }
          this.setState({
            freetextAnswers: this.state.freetextAnswers
          })
        })
      },
      counter: this.state.resultsFetchingCounter
    }
  }

  updateView(controlsState) {
    const [mainArgs, comparisonArgs] = buildArguments(controlsState)
    console.log(mainArgs, comparisonArgs)

    this.setState({
      fetchingMainData: true,
      changed: false,
      currentSemester: controlsState.selectedSemester
    })

    if (this.state.mainResults !== null || mainArgs !== this.state.currentMainArgs) {
      this.fetchMainResults(mainArgs)
    } else {
      console.log("No need to re-fetch main results")
      this.setState({ fetchingMainData: false })
    }

    if (controlsState.compareWith !== "none") {
      let comparisonId = null;
      if (controlsState.compareWith === "city") {
        comparisonId = this.props.school.city_slug;
      } else if (controlsState.compareWith === "country") {
        comparisonId = this.props.school.country_code
      } else if (controlsState.compareWith === "class") {
        comparisonId = controlsState.compareWithClass
      }
      this.fetchComparisonResults(controlsState.compareWith, this.props.form.target_audience, comparisonId, comparisonArgs)
    } else {
      this.setState({ fetchingMainData: false, compareWith: controlsState.compareWith })
    }

  }

  fetchMainResults(args) {
    // seems that React context relies directly on the same object, thus we can't assign
    // a new {}, but need to clear the old answers one by one
    Object.getOwnPropertyNames(this.state.freetextAnswers).forEach((prop) => {
      delete this.state.freetextAnswers[prop]
    })
    fetchResultsData(buildResultsUrl(this.props.form.id, args))
      .then((obj) => {
        this.setState({
          mainResults: obj,
          currentMainArgs: args,
          freetextAnswers: this.state.freetextAnswers,
          fetchingMainData: false,
          resultsFetchingCounter: this.state.resultsFetchingCounter + 1
        })
      }).catch(err => {
        this.setState({
          fetchingMainData: false,
          fetchingComparisonData: false
        })
      })
  }

  fetchComparisonResults(comparisonType, targetAudience, comparisonId, args) {
    this.setState({ fetchingComparisonData: true })
    // add our form ID if comparing with another class
    if (comparisonType === "class") {
      comparisonId = `${this.props.form.id},${comparisonId}`
    }
    else if (comparisonType === "last_year") {
      comparisonId = `form,${this.props.form.id},${parseInt(this.state.currentSemester) - 1}`
    }

    fetchComparisonData(comparisonType, targetAudience, comparisonId, args)
      .then((comp) => {
        comp = comp.error !== true ? comp : null

        this.setState({
          comparisonResults: comp,
          compareWith: comparisonType,
          fetchingComparisonData: false
        })
      }).catch(err => {
        this.setState({
          fetchingMainData: false,
          fetchingComparisonData: false
        })
      })
  }

  render() {
    const comparisonData = this.state.compareWith !== "none" && this.state.comparisonResults ?
      [{
        data: this.state.comparisonResults,
        with: this.state.compareWith,
        name: I18N._(COMPARISON_NAMES[this.state.compareWith])
      }] :
      []

    let mainSourceName = I18N._("us")
    if (comparisonData.length > 0 && comparisonData[0].with === "class") {
      const filters = this.state.mainResults.filters
      mainSourceName = sourceNameFromFilters(filters)
      comparisonData[0].name = sourceNameFromFilters(comparisonData[0].data.filters)
    }

    return (
      <ResultsDataContext.Provider value={this.getResultsDataContext()}>
        <div className="container">
          <FormControls {...this.props} updateCallback={this.updateView} />
          {
            (this.state.fetchingMainData || this.state.fetchingComparisonData) ?
              <FetchingDataBar />
              :
              <>
                {this.state.mainResults !== null &&
                  <Results
                    resultsSet={this.state.mainResults}
                    mainSourceName={mainSourceName}
                    comparisons={comparisonData}
                  />}
              </>
          }
        </div>
      </ResultsDataContext.Provider>
    )
  }
}
