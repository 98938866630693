import React from 'react';
import { ViewChooser, ComparisonChooser, ExportButtons } from './ControlPanelWidgets';
import SharingModal from './SharingModal';


export default class Controls extends React.Component {

  static defaultProps = {
    semester: null,
    semester_start_year: null,
    available_semesters: [],
    updateCallback: null,
    comparisons: [],
    ready: false,
    target_audience: null,
    city_slug: null,
    exportCallback: null
  };

  constructor(props) {
    super(props);

    const availableComparisons = [
      { id: "select", name: I18N._("Select comparison"), disabled: true },
      { id: "separator1", name: "──────────", disabled: true },
      { id: "last_year", name: I18N._("Last year"), type: "time" },
      { id: "fi", name: I18N._("Country results"), type: "country" },
      { id: "separator2", name: "──────────", disabled: true },
      ...props.my_schools.map((school) => ({ type: "school", ...school }))
    ]

    this.state = {
      selectedSemester: props.semester,
      selectedView: "all",
      separateGenders: false,
      separateGrades: false,
      compareWith: "none",
      changed: false,
      selectedComparisons: [],
      availableComparisons,
      showSharingModal: false
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateView = this.updateView.bind(this);
    this.addComparison = this.addComparison.bind(this);
    this.removeComparison = this.removeComparison.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedSemester) {
      console.log("CityControls - doing initial load");
      this.updateView()
    }
  }

  handleInputChange(event) {
    const target = event.target
    let value = target.value
    if (target.type === 'checkbox') {
      value = target.checked
    } else if (target.type === 'select-multiple') {
      value = Array.from(target.selectedOptions, option => option.value);
    }
    const name = target.name;
    this.setState({
      [name]: value,
      changed: true
    });
  }

  addComparison(event) {
    const target = event.target
    const value = target.value
    const comparisonElement = this.state.availableComparisons.find(({ id }) => `${id}` === value)
    if (this.state.selectedComparisons.indexOf(comparisonElement) === -1) {
      this.setState({
        selectedComparisons: [...this.state.selectedComparisons, comparisonElement],
        changed: true
      })
    }
  }

  removeComparison(comparisonId, event) {
    event.preventDefault()
    this.setState({
      selectedComparisons: this.state.selectedComparisons.filter(({ id }) => id !== comparisonId),
      changed: true
    })
  }

  updateView(event) {
    event && event.preventDefault();
    this.props.updateCallback(this.state)
  }

  render() {

    const currentView = {
      semester: this.state.selectedSemester,
      comparisons: this.state.selectedComparisons.map((c) => c.type) || [],
      view: this.state.selectedView,
      gendersSeparated: this.state.separateGenders,
      citySlug: this.props.city_slug,
      targetAudience: this.props.target_audience
    }

    const schoolsWithoutEnoughAnswers = this.props.comparisons
      .filter(({ error }) => error)

    if (!this.props.ready) {
      return null
    }

    return (
      <>
        <div className="row">
          <hr />
          <h2 className="col-sm-12">{I18N._("Overview of the results")}</h2>
        </div>

        <form className="row form-control-panel" onSubmit={() => false}>
          <div className="col-sm-3">
            <h4> {I18N._("View")}: </h4>
            <div className="form-group">
              <ViewChooser
                name="selectedView"
                value={this.state.selectedView}
                onChange={this.handleInputChange}
                availableClasses={[]}
                targetAudience={this.props.target_audience} />
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox"
                  name="separateGenders"
                  onChange={this.handleInputChange}
                  checked={this.state.separateGenders} />
                {I18N._("Genders separated")}
              </label>
            </div>
          </div>

          <div className="col-sm-3">
            <h4> {I18N._("Compare with")}: </h4>
            <div className="form-group">
              <ComparisonChooser onChange={this.addComparison} available={this.state.availableComparisons} />
            </div>
          </div>


          <div className="col-sm-3 selected-comparisons">
            {
              this.state.selectedComparisons.map(({ id, name }) =>
                <label key={id}>
                  {name}
                  <button value={id} onClick={(e) => this.removeComparison(id, e)}>
                    <span className="glyphicon glyphicon-remove" />
                  </button>
                </label>)
            }
          </div>

          <div className="col-sm-3 submit">
            <div className="align-to-right">
              <button className={'btn btn-primary'}
                onClick={this.updateView}
                disabled={!this.state.changed}
              >{I18N._("Update")}</button>

              <ExportButtons
                excelCallback={() => this.props.exportCallback(this.state)}
                shareCallback={() => this.setState({ showSharingModal: !this.state.showSharingModal })}
              />
            </div>
          </div>
        </form>
        <div className="row fetching-warnings">
          {schoolsWithoutEnoughAnswers.length > 0 &&
            schoolsWithoutEnoughAnswers.map(({ id, schoolName }) =>
              <div role="alert" key={id} className="alert alert-info">
                {I18N._("Too few answers provided")}: {schoolName}
              </div>
            )
          }
        </div>

        {this.state.showSharingModal &&
          <SharingModal
            currentView={currentView}
            closeCallback={() => this.setState({ showSharingModal: !this.state.showSharingModal })}
          />
        }
      </>
    )
  }
}
