import ReactDOM from 'react-dom'
import React from 'react'
import log from '../log'



export function handle__react_renders(components) {
  document.querySelectorAll('.__react_render').forEach((elem) => {
    const componentType = elem.dataset['component']
    const Component = components[componentType]
    if (!Component) {
      console.error("Can't find react component " + componentType)
    }
    try {
      const componentProps = JSON.parse(elem.dataset['params'])
      ReactDOM.render(
        <Component {...componentProps} />,
        elem
      )
    } catch (err) {
      console.error(err)
      console.log("-------------------------------")
      console.log(elem.dataset['component'])
      console.log(elem.dataset['params'])
      console.log("-------------------------------")
    }
  })
}