import React from 'react';

import scrollTo from '../../scrollTo'

const expandGraph = (evt) => {
  const elem = evt.target
  if (elem.classList.contains("graph")) {
    elem.classList.toggle("expanded")
    setTimeout(() => {
      scrollTo(elem.offsetTop, 400)
    }, 100)
  }
}

const ExpandableChart = ({ ChartClass, params, className, vertical }) => {
  return <div className={"graph expandable-graph " + className} onClick={expandGraph}>
    <ChartClass {...params} vertical={vertical} />
  </div>
}

export default ExpandableChart