import React from 'react';
import ReactDOM from 'react-dom';

import ExtraQuestionsWidget from './components/ExtraQuestionsWidget';
import NewFormQuestionAreas from './components/NewFormQuestionAreas';

var EXTRA_QUESTION_RUNNING_INDEX = 0;

export default (opts) => {
  const rootElem = $('#question-areas-container').get(0)
  const extraQuestionsElem = $('#extra-questions-widget').get(0)

  ReactDOM.render(
    <NewFormQuestionAreas question_areas={opts.question_areas} />, rootElem);

  ReactDOM.render(
    <ExtraQuestionsWidget />, extraQuestionsElem);

  $('input[name=audience]').on('change', function (e) {
    const v = $(this).val()
    ReactDOM.render(
      <NewFormQuestionAreas question_areas={[]} />,
      rootElem);
    $.get(opts.get_question_areas_url.replace('555', v))
      .done(function (data) {
        // log('received question areas', data)
        ReactDOM.render(
          <NewFormQuestionAreas question_areas={data} />,
          rootElem);
      })
  })
}
